<template>
  <div class="container">
    <div>
      <vs-button @click="showNewIntervention = true">
        Nueva Intervención
      </vs-button>
    </div>
    <div>
      <h2>Próximas intervenciones</h2>
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th colSpan="2">
              Intervención
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i" v-for="(tr, i) in interventions" :data="tr">
            <vs-td>
              {{ tr.name }}
            </vs-td>
            <vs-td>
              <vs-button
                size="mini"
                :warn="filterNextEvents(tr).status === 1"
                :success="filterNextEvents(tr).status === 2"
                to="/interventions"
              >
                {{ filterNextEvents(tr).text }}
              </vs-button>
            </vs-td>
            <!-- <template #expand>
              <div class="con-content">
                <vs-row class="userData">
                  <vs-col
                    :w="12 / tr.results.length"
                    v-for="(result, ind) in tr.results"
                    :key="'result' + ind"
                  >
                    <vs-button
                      flat
                      :success="result.status === 3"
                      :active="result.status === 3"
                      :disabled="result.status === 1"
                    >
                      {{ result.name }}
                    </vs-button>
                  </vs-col>
                </vs-row>
              </div>
            </template> -->
          </vs-tr>
        </template>
        <template #notFound>
          No hay ningúna intervención activa o pendiente
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import { patientMix, protocolMix } from "@/mixins/mixins.js";

export default {
  name: "AgendaPage",
  mixins: [patientMix, protocolMix],
  data() {
    return {
      code: "",
      standardProtocols: null,
      answers: null,
      interventions: [],
    };
  },
  methods: {
    async initData() {
      const { currentUserInfo } = this.$store.state;
      const { uid } = currentUserInfo.user || currentUserInfo;
      this.interventions = await this.getInterventions(uid);
      this.standardProtocols = await this.getStandardProtocols();
      // this.answers.standards = this.standardProtocols;
      // this.filterNextEvents();
    },
    filterNextEvents(intervention) {
      const filtered = intervention.results.filter(
        (res) => res.status === 2 && res.activateDay <= new Date().getTime()
      );
      if (filtered.length > 0) {
        return {
          text: "Disponible",
          status: 2,
        };
      } else {
        const a = intervention.results.filter(
          (res) => res.activateDay > new Date().getTime()
        );
        return {
          text:
            a.length > 0
              ? new Date(a[0].activateDay).toLocaleDateString()
              : "Completado",
          status: a.length > 0 ? 1 : 4,
        };
      }
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style scoped>
.container {
  text-align: justify;
  padding: 4rem 1rem 0rem 4.5rem;
}

.dialogActionBtns {
  display: flex;
  justify-content: space-between;
}

.userInfo {
  text-align: start;
}

.inputForm {
  margin: 2rem 1rem;
}
</style>
<style>
.inputForm > div > input {
  width: 100%;
}
</style>
